import { useState } from "react";
import { useUpdateContact } from "../api/useUpdateContact";
import styles from "./ContactForm.module.scss";
import { Button, TextField, Toast } from "@shopify/polaris";
import { trackEvent } from "../lib/mixpanel";

export function ContactForm({ data }: { data: Record<string, any> }) {
  const [formState, setFormState] = useState(data);
  const [isSuccessToastActive, setIsSuccessToastActive] = useState(false);
  const { isLoading, mutateAsync } = useUpdateContact(data.id);

  return (
    <div className={styles.layout}>
      <section className={styles.settingsSection}>
        <form
          onSubmit={async (e) => {
            e.preventDefault();
            await mutateAsync(formState);
            trackEvent("Contact Settings Updated", formState);
            setIsSuccessToastActive(true);
          }}
          className={styles.formWrapper}
        >
          <TextField
            autoComplete=""
            label="Phone Number"
            placeholder="919876543210"
            value={formState.phoneNumber}
            helpText="Include your country code in the number and do not write any dashes, plus or spaces."
            onChange={(val) => {
              setFormState({ ...formState, phoneNumber: val });
            }}
          />

          <TextField
            multiline={3}
            autoComplete=""
            placeholder="{{page.url}} I'm interested in this product and I have a few questions. Can you help?"
            label="Prefilled Text"
            helpText="This message will be prefilled in the chat widget when the user clicks on the chat button. Use {{page.url}} to include the URL of the page the user is on."
            value={formState.prefilledMessage}
            onChange={(val) => {
              setFormState({ ...formState, prefilledMessage: val });
            }}
          />

          <Button submit loading={isLoading}>
            Save Changes
          </Button>
          {isSuccessToastActive && (
            <Toast
              content="Settings Saved Successfully. Don't forget to add widget to store."
              onDismiss={() => setIsSuccessToastActive(false)}
              duration={5000}
            />
          )}
        </form>
      </section>
    </div>
  );
}
