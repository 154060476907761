import { Button, List, Text } from "@shopify/polaris";
import { Modal } from "@shopify/polaris";
import { useState } from "react";
import { trackEvent } from "../lib/mixpanel";
import { ToggleOnIcon } from "@shopify/polaris-icons";
import Step1 from "../assets/app-enable-step1.jpg";
import Step2 from "../assets/app-enable-step2.jpg";
import Step3 from "../assets/app-enable-step3.jpg";
import Step4 from "../assets/app-enable-step4.jpg";
import Step5 from "../assets/app-enable-step5.jpg";

export function AppEnableDisableButton() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <Modal
        onClose={() => {
          setIsModalOpen(false);
        }}
        open={isModalOpen}
        title="Adding widget on store"
      >
        <Modal.Section>
          <List type="number">
            <div style={{ paddingTop: 20 }}>
              <List.Item>
                <Text as="p" variant="bodyLg" fontWeight="medium">
                  Go to Online Store &gt; Themes
                </Text>
                <div style={{ paddingTop: 20 }} />
                <img
                  src={Step1}
                  style={{
                    maxWidth: "100%",
                    height: "auto",
                    objectFit: "contain",
                  }}
                />
              </List.Item>
            </div>
            <div style={{ paddingTop: 20 }}>
              <List.Item>
                <Text as="p" variant="bodyLg" fontWeight="medium">
                  Click on Customize Theme
                </Text>
                <div style={{ paddingTop: 20 }} />
                <img
                  src={Step2}
                  style={{
                    maxWidth: "100%",
                    height: "auto",
                    objectFit: "contain",
                  }}
                />
              </List.Item>
            </div>
            <div style={{ paddingTop: 20 }}>
              <List.Item>
                <Text as="p" variant="bodyLg" fontWeight="medium">
                  In the Apps Section at the end, Click Add block
                </Text>
                <div style={{ paddingTop: 20 }} />
                <img
                  src={Step3}
                  style={{
                    maxWidth: "100%",
                    height: "auto",
                    objectFit: "contain",
                  }}
                />
              </List.Item>
            </div>
            <div style={{ paddingTop: 20 }}>
              <List.Item>
                <Text as="p" variant="bodyLg" fontWeight="medium">
                  Add the Block with name Whatsapp Customer Support App
                </Text>
                <div style={{ paddingTop: 20 }} />
                <img
                  src={Step4}
                  style={{
                    maxWidth: "100%",
                    height: "auto",
                    objectFit: "contain",
                  }}
                />
              </List.Item>
            </div>
            <div style={{ paddingTop: 20 }}>
              <List.Item>
                <Text as="p" variant="bodyLg" fontWeight="medium">
                  Click Save
                </Text>
                <div style={{ paddingTop: 20 }} />
                <img
                  src={Step5}
                  style={{
                    maxWidth: "100%",
                    height: "auto",
                    objectFit: "contain",
                  }}
                />
              </List.Item>
            </div>
          </List>
        </Modal.Section>
      </Modal>
      <Button
        icon={ToggleOnIcon}
        onClick={async () => {
          setIsModalOpen(true);
          trackEvent("Add Widget to Storefront Clicked");
        }}
      >
        Add widget on store
      </Button>
    </>
  );
}
