import { Button, TextField, Toast } from "@shopify/polaris";
import styles from "./WidgetSettingsForm.module.scss";
import { useUpdateFloatingWidget } from "../api/useUpdateFloatingWidget";
import { useState } from "react";
import { RangeInput } from "./RangeInput";
import { ColorPicker } from "./ColorPicker";
import { iconSets } from "../lib/iconSets";
import classNames from "classnames";
import { WidgetPreview } from "./WidgetPreview";
import { trackEvent } from "../lib/mixpanel";

interface Props {
  data: any;
}

export function WidgetSettingsForm({ data }: Props) {
  const [formState, setFormState] = useState(data);
  const { isLoading, mutateAsync } = useUpdateFloatingWidget();
  const [isSuccessToastActive, setIsSuccessToastActive] = useState(false);

  const hasText = formState.text.length > 0;

  return (
    <div className={styles.layout}>
      <section className={styles.settingsSection}>
        <form
          onSubmit={async (e) => {
            e.preventDefault();
            await mutateAsync(formState);
            trackEvent("Widget Settings Updated", formState);
            setIsSuccessToastActive(true);
          }}
          className={styles.formWrapper}
        >
          <div>
            <h3 className={styles.iconTitle}>Icon</h3>
            <div className={styles.iconSets}>
              {Object.entries(iconSets).map(([key, value]) => (
                <button
                  type="button"
                  onClick={() => {
                    setFormState({
                      ...formState,
                      icon: key,
                    });
                  }}
                  className={classNames(styles.iconSet, {
                    [styles.selectedIconSet]: key === formState.icon,
                  })}
                >
                  <span style={{ color: "#454545" }} className={styles.icon}>
                    {value.icon}
                  </span>
                  <span> {value.name}</span>
                </button>
              ))}
            </div>
          </div>

          <TextField
            autoComplete=""
            label="Text"
            value={formState.text}
            onChange={(val) => {
              setFormState({ ...formState, text: val });
            }}
          />

          <ColorPicker
            label="Background Color"
            onChange={(value) => {
              setFormState({
                ...formState,
                backgroundColorInRGBA: value,
              });
            }}
            value={formState.backgroundColorInRGBA}
          />

          <ColorPicker
            label="Icon Color"
            onChange={(value) => {
              setFormState({
                ...formState,
                iconColorInRGBA: value,
              });
            }}
            value={formState.iconColorInRGBA}
          />
          {hasText && (
            <ColorPicker
              label="Text Color"
              onChange={(value) => {
                setFormState({
                  ...formState,
                  textColorInRGBA: value,
                });
              }}
              value={formState.textColorInRGBA}
            />
          )}

          <RangeInput
            label="Icon Size"
            suffix={`${formState.iconSize}px`}
            value={formState.iconSize}
            onChange={(val) => {
              setFormState({ ...formState, iconSize: val });
            }}
          />
          {hasText && (
            <RangeInput
              label="Text Size"
              value={formState.textSize}
              suffix={`${formState.textSize}px`}
              onChange={(val) => {
                setFormState({ ...formState, textSize: val });
              }}
            />
          )}
          {hasText && (
            <RangeInput
              suffix={`${formState.gap}px`}
              label="Gap"
              value={formState.gap}
              onChange={(val) => {
                setFormState({ ...formState, gap: val });
              }}
            />
          )}
          <RangeInput
            label="Border Radius"
            suffix={`${formState.borderRadius}px`}
            value={formState.borderRadius}
            onChange={(val) => {
              setFormState({ ...formState, borderRadius: val });
            }}
          />
          <RangeInput
            label="Position Bottom"
            suffix={`${formState.positionBottom}px`}
            value={formState.positionBottom}
            onChange={(val) => {
              setFormState({ ...formState, positionBottom: val });
            }}
          />
          <RangeInput
            label="Position Right"
            suffix={`${formState.positionRight}px`}
            value={formState.positionRight}
            onChange={(val) => {
              setFormState({ ...formState, positionRight: val });
            }}
          />
          <RangeInput
            label="Padding Vertical"
            value={formState.paddingVertical}
            suffix={`${formState.paddingVertical}px`}
            onChange={(val) => {
              setFormState({ ...formState, paddingVertical: val });
            }}
          />
          <RangeInput
            label="Padding Horizontal"
            value={formState.paddingHorizontal}
            suffix={`${formState.paddingHorizontal}px`}
            onChange={(val) => {
              setFormState({ ...formState, paddingHorizontal: val });
            }}
          />

          <Button submit loading={isLoading}>
            Save Changes
          </Button>
        </form>
        {isSuccessToastActive && (
          <Toast
            content="Settings Saved Successfully. Don't forget to add widget to store"
            onDismiss={() => setIsSuccessToastActive(false)}
            duration={5000}
          />
        )}
      </section>
      <section className={styles.previewSection}>
        <WidgetPreview formState={formState} />
      </section>
    </div>
  );
}
