import { useGetFloatingWidget } from "../api/useGetWidget";
import { WidgetSettingsForm } from "../components/WidgetSettingsForm";
import { useListContacts } from "../api/useListContacts";
import { ContactForm } from "../components/ContactForm";
import { Tabs } from "@shopify/polaris";
import { useState } from "react";
import styles from "./SettingsPage.module.scss";
import { AppEnableDisableButton } from "../components/AppEnableDisableButton";

export function SettingsPage() {
  const [selectedTab, setSelectedTab] = useState(0);
  const tabs = [
    {
      id: "contact-settings",
      content: "Contact settings",
      panelID: "contact-settings-panel",
    },
    {
      id: "widget-settings",
      content: "Widget settings",
      accessibilityLabel: "Widget settings",
      panelID: "widget-settings-panel",
    },
  ];

  return (
    <main className={styles.page}>
      <div className={styles.header}>
        <h1 className={styles.pageTitle}>Settings</h1>
        <AppEnableDisableButton />
      </div>
      <div className={styles.tabWrapper}>
        <Tabs tabs={tabs} selected={selectedTab} onSelect={setSelectedTab} />
      </div>
      {selectedTab === 0 && <ContactFormLoader />}
      {selectedTab === 1 && <WidgetSettingsLoader />}
    </main>
  );
}

function WidgetSettingsLoader() {
  const { data, isLoading, isError } = useGetFloatingWidget();

  if (isLoading) {
    return <p>Loading...</p>;
  }

  if (isError) {
    return <p>Something went wrong</p>;
  }

  return <WidgetSettingsForm data={data.data} />;
}

function ContactFormLoader() {
  const {
    data: contactData,
    isLoading: contactIsLoading,
    isError: contactIsError,
  } = useListContacts();

  if (contactIsLoading) {
    return <p>Loading...</p>;
  }

  if (contactIsError) {
    return <p>Something went wrong</p>;
  }

  return <ContactForm data={contactData.data[0]} />;
}
