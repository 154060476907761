import { useCallback, useEffect, useRef, useState } from "react";
import styles from "./WidgetPreview.module.scss";
import { getShopName } from "../lib/getShopName";

interface WidgetPreviewProps {
  formState: Record<string, any>;
}

const shop = getShopName();

export function WidgetPreview({ formState }: WidgetPreviewProps) {
  const previewRef = useRef<HTMLIFrameElement>(null);
  const widgetPreviewUrl = `${process.env.REACT_APP_WIDGET_PREVIEW_URL}?shop=${shop}&isDevelopment=true`;

  const [isWidgetPreviewInitiated, setIsWidgetPreviewInitiated] =
    useState(false);

  useEffect(() => {
    // iframe not connected yet
    if (!previewRef.current) return;

    const contentWindow = previewRef.current.contentWindow;

    if (!contentWindow) return;

    contentWindow.postMessage({ type: "FORM_UPDATED", formState }, "*");
  }, [formState, isWidgetPreviewInitiated]);

  const messageReceiver = useCallback((e: MessageEvent) => {
    console.log("Message received from the child: " + e.data); // Message received from child
    if (e.data === "INITIATED") {
      setIsWidgetPreviewInitiated(true);
    }
  }, []);

  useEffect(() => {
    // FIXME: Add domain checks to ensure secure message transfer
    window.addEventListener("message", messageReceiver);

    return () => {
      window.removeEventListener("message", messageReceiver);
    };
  }, []);

  return (
    <div className={styles.wrapper}>
      <div className={styles.browserWindow}>
        <div className={styles.browserWindowDot} />
        <div className={styles.browserWindowDot} />
        <div className={styles.browserWindowDot} />
      </div>
      <iframe
        ref={previewRef}
        className={styles.preview}
        src={widgetPreviewUrl}
        width="500"
        height="500"
      />
    </div>
  );
}
